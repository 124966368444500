import Vue from 'vue'

import App from './App.vue'
import router from './router'
import '@/styles/common.less'

// 引入ElementUI
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
//引入echarts文件
import * as echarts from 'echarts'
// 引入moment
import moment from 'moment'

import '@/styles/common.less'
import './assets/icons' // icon

Vue.use(ElementUI, {size: 'small'})

Vue.prototype.$echarts = echarts

Vue.prototype.$moment = moment

Vue.config.productionTip = false
new Vue({
  router,
  render: (h) => h(App)
}).$mount('#app')
