import Vue from 'vue'
import VueRouter from 'vue-router'

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import("@/layout/LayoutMain.vue"),
      redirect: 'index',
      children: [
        {
          path: 'index',
          component: () => import('@/views/HomeView.vue')
        },
        {
          path: 'merchant',
          component: () => import('@/views/merchant/MerchantView.vue')
        },
        {
          path: 'merchantuser',
          component: () => import('@/views/merchant/MerchantUserView.vue')
        },
        {
          path: 'leander',
          component: () => import('@/views/merchant/LeanderView.vue')
        },
        {
          path: 'riskuser',
          component: () => import('@/views/riskuser/RiskUserView.vue')
        },
        {
          path: 'loan',
          component: () => import('@/views/loan/LoanView.vue')
        },
        {
          path: 'version',
          component: () => import('@/views/system/VersionView.vue')
        },
        {
          path: 'blacklist',
          component: () => import('@/views/blacklist/BlacklistView.vue')
        },
        {
          path: 'menu',
          component: () => import('@/views/system/MenuView.vue')
        },
        {
          path: 'permission',
          component: () => import('@/views/system/PermissionView.vue')
        },
        {
          path: 'role',
          component: () => import('@/views/system/RoleView.vue')
        }
      ]
    },
    {
      path: '/login',
      name: 'login',
      component: () => import("@/views/login/LoginView.vue")
    }
  ]
})

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('token')
  if (to.name !== 'login' && !token) {
    router.push('/login')
  } else {
    next()
  }
})

export default router
